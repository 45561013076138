import {
  createRootRouteWithContext,
  createRoute,
  createRouter,
  Outlet,
  redirect,
} from "@tanstack/react-router";
import { ImportScreen } from "@/app/Import/Screens/ImportScreen";
import { ToolsScreen } from "@/app/Tools/Screens/ToolsScreen";
import { WaitlistsScreen } from "@/app/Waitlists/Screens/WaitlistsScreen";
import {
  bookingChangeRequestsRoute,
  bookingCreateRoute,
  bookingDetailRoute,
  bookingFindRelocationRoute,
  bookingPaymentsRoute,
  bookingRecordLayout,
  bookingRelocationRoute,
  bookingRoute,
  bookingsRoute,
  bookingTableRoute,
  bookingTasksRoute,
} from "@/app/Bookings/Routes/bookingRoutes";
import {
  supplierBillingRoute,
  supplierInsuranceRoute,
  supplierRecordLayout,
  suppliersRoute,
  supplierTableRoute,
} from "./app/Suppliers/Routes/supplierRoutes";
import {
  supplierActiveLinesRoute,
  supplierActivityRoute,
  supplierBookingsRoute,
  supplierCreateRoute,
  supplierDefaultHoursRoute,
  supplierDetailRoute,
  supplierLogisticsRoute,
  supplierOfficesRoute,
  supplierRecordRoute,
  supplierRelocationsRoute,
  supplierVehiclesRoute,
} from "@/app/Suppliers/Routes/supplierRoutes";
import {
  invoiceDetailRoute,
  invoiceRecordLayoutRoute,
  invoicesRoute,
  invoiceTableRoute,
} from "./app/Invoices/Routes/invoiceRoutes";
import {
  invoiceActivityRoute,
  invoiceCreateRoute,
  invoiceRoute,
} from "@/app/Invoices/Routes/invoiceRoutes";
import {
  vehicleActivityRoute,
  vehicleCreateRoute,
  vehicleDetailRoute,
  vehicleEmptyRoute,
  vehicleImagesRoute,
  vehicleListRoute,
  vehicleRoute,
} from "@/app/Vehicles/Routes/vehicleRoutes";
import {
  userDetailRoute,
  userListRoute,
  userRoute,
} from "@/app/Users/Routes/userRoutes";

import {
  paymentDetailRoute,
  paymentListRoute,
  paymentRoute,
} from "@/app/Payments/Routes/paymentRoute";
import {
  paidDriverDetailRoute,
  paidDriverRoute,
  paidDriversListRoute,
  paidDriverTableRoute,
} from "@/app/PaidDrivers/PaidDrivers/Routes/paidDriverRoutes";
import {
  myOrganisationRoute,
  organisationListRoute,
  organisationRoute,
} from "@/app/Organisations/Routes/organisationRoutes";
import {
  officeActivityRoute,
  officeBookingsRoute,
  officeCreateRoute,
  officeDetailRoute,
  officeEmptyRoute,
  officeListRoute,
  officeOpenHoursRoute,
  officeRelocationsRoute,
  officeRoute,
} from "@/app/Offices/Routes/officeRoutes";
import {
  cityCreateRoute,
  cityDetailRoute,
  cityListRoute,
  cityRoute,
  cityTripRoute,
} from "@/app/Cities/Cities/Routes/cityRoutes";
import {
  contactDetailRoute,
  contactEntityRoute,
  contactInvoiceRoute,
  contactRoute,
  contactsRoute,
  contactStatementRoute,
} from "@/app/Contacts/Routes/contactRoute";
import { useAuth, useUser } from "@clerk/clerk-react";
import {
  relocationActivityRoute,
  relocationBookingsRoute,
  relocationCreateRoute,
  relocationDetailsRoute,
  relocationLinesRoute,
  relocationRecordLayout,
  relocationRoute,
  relocationsRoute,
  relocationTableRoute,
  relocationWaitlistRoute,
} from "@/app/Relocations/Routes/relocationRoute";
import {
  reportBookingsRoute,
  reportCancellationsRoute,
  reportInvoicesRoutes,
  reportMovementRoute,
  reportPaymentRoute,
  reportRelocationsRoute,
  reportRoute,
  reportSalesRoute,
  reportSelectionRoute,
  reportSupplierRoute,
} from "@/app/Reports/Routes/reportRoutes";
import {
  settingBillingRoute,
  settingIntegrationsListRoute,
  settingIntegrationsRoute,
  settingNotificationRoute,
  settingsAvailabilityRoute,
  settingsCitiesRoute,
  settingsDefaultRatesRoute,
  settingsExtrasRoute,
  settingsGeneralRoute,
  settingsInsuranceRoute,
  settingsIntegrationRotue,
  settingsMarketingRoute,
  settingsOfficeRoute,
  settingsOrganisationMembersRoute,
  settingsOrganisationRoute,
  settingsOrganisationsRoute,
  settingsReportRoute,
  settingsRoute,
  settingsSuppliersRoute,
  settingsVehicleRoute,
} from "@/app/Settings/Settings/Routes/settingsRoute";
import {
  changeOrganisationRoute,
  signInRoute,
  signUpRoute,
} from "@/app/Auth/Routes/loginRoutes";
import { AppLayout } from "@/lib/Components/Layout/Layout/Layout";
import { AuthLayout } from "@/app/Auth/Layouts/AuthLayout";
import { prefetchQuery } from "@/lib/GraphQLCodegen/fetcher";
import { globalSupplierSelectionList } from "@/app/Suppliers/GraphQL/globalSupplierSelectionList";
import { ErrorScreen } from "@/lib/Components/Screens/ErrorScreen";
import {
  onboardingCreateOrgRoute,
  onboardingCreateOrJoinRoute,
  onboardingPricingRoute,
  onboardingRoute,
} from "@/app/Onboarding/Routes/onboardingRoutes";
import { metaQuery } from "@/app/Meta/GraphQL/metaQuery";
import { dashboardRoute } from "@/app/Dashboard/routes/dashboardRoutes";

type UseAuthReturn = ReturnType<typeof useAuth>;
type UseUserReturn = ReturnType<typeof useUser>;

interface MyRouterContext {
  auth: UseAuthReturn;
  user: UseUserReturn;
}

const rootRoute = createRootRouteWithContext<MyRouterContext>()({
  component: Outlet,
});

export const appLayoutRoute = createRoute({
  errorComponent: ErrorScreen,
  beforeLoad: async ({ context }) => {
    const { isSignedIn, orgId } = context.auth;

    if (!isSignedIn) {
      window.location.href = "/sign-in";

      // TODO: this redirect does not work, the route is not loaded
      // throw redirect({
      //   to: "/sign-in",
      //   search: {
      //     // Use the current location to power a redirect after login
      //     // (Do not use `router.state.resolvedLocation` as it can
      //     // potentially lag behind the actual current location)
      //     // redirect: location.href,
      //   },
      // });
    }

    if (orgId) {
      return;
    }

    throw redirect({
      to: changeOrganisationRoute.to,
    });
  },
  getParentRoute: () => rootRoute,
  id: "_app",
  loader: ({ context: { auth } }) => {
    return Promise.all([
      prefetchQuery(
        globalSupplierSelectionList,
        {
          page: 1,
          first: 200,
        },
        auth,
      ),
      prefetchQuery(metaQuery, {}, auth),
    ]);
  },
  component: AppLayout,
});

export const loginLayoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: "_login",
  component: AuthLayout,
});

export const waitlistsRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/waitlists",
  component: WaitlistsScreen,
});

export const importRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/import",
  component: ImportScreen,
});

export const toolsRoute = createRoute({
  getParentRoute: () => appLayoutRoute,
  path: "/tools",
  component: ToolsScreen,
});

// Create the route tree using your routes
const routeTree = rootRoute.addChildren([
  appLayoutRoute.addChildren([
    dashboardRoute,
    suppliersRoute.addChildren([
      supplierTableRoute,
      supplierRecordLayout.addChildren([
        supplierCreateRoute,
        supplierRecordRoute.addChildren([
          supplierDetailRoute,
          supplierVehiclesRoute,
          supplierOfficesRoute,
          supplierRelocationsRoute,
          supplierDefaultHoursRoute,
          supplierActiveLinesRoute,
          supplierActivityRoute,
          supplierLogisticsRoute,
          supplierBookingsRoute,
          supplierInsuranceRoute,
          supplierBillingRoute,
        ]),
      ]),
    ]),
    bookingsRoute.addChildren([
      bookingTableRoute,
      bookingRecordLayout.addChildren([
        bookingCreateRoute,
        bookingRoute.addChildren([
          bookingDetailRoute,
          bookingRelocationRoute,
          bookingFindRelocationRoute,
          bookingChangeRequestsRoute,
          bookingPaymentsRoute,
          bookingTasksRoute,
        ]),
      ]),
    ]),
    relocationTableRoute,
    relocationsRoute.addChildren([
      relocationTableRoute,
      relocationRecordLayout.addChildren([
        relocationCreateRoute,
        relocationRoute.addChildren([
          relocationDetailsRoute,
          relocationWaitlistRoute,
          relocationLinesRoute,
          relocationActivityRoute,
          relocationBookingsRoute,
        ]),
      ]),
    ]),
    vehicleListRoute.addChildren([
      vehicleCreateRoute,
      vehicleRoute.addChildren([
        vehicleDetailRoute,
        vehicleImagesRoute,
        vehicleActivityRoute,
        vehicleEmptyRoute,
      ]),
    ]),
    cityListRoute.addChildren([
      cityCreateRoute,
      cityRoute.addChildren([cityDetailRoute, cityTripRoute]),
    ]),
    officeListRoute.addChildren([
      officeEmptyRoute,
      officeCreateRoute,
      officeRoute.addChildren([
        officeDetailRoute,
        officeOpenHoursRoute,
        officeActivityRoute,
        officeBookingsRoute,
        officeRelocationsRoute,
      ]),
    ]),
    waitlistsRoute,
    importRoute,
    contactsRoute.addChildren([
      contactRoute.addChildren([
        contactDetailRoute,
        contactEntityRoute,
        contactInvoiceRoute,
        contactStatementRoute,
      ]),
    ]),
    userListRoute.addChildren([userRoute.addChildren([userDetailRoute])]),
    reportRoute.addChildren([
      reportSelectionRoute,
      reportBookingsRoute,
      reportRelocationsRoute,
      reportInvoicesRoutes,
      reportSupplierRoute,
      reportPaymentRoute,
      reportSalesRoute,
      reportCancellationsRoute,
      reportMovementRoute,
    ]),
    toolsRoute,
    myOrganisationRoute,
    organisationListRoute.addChildren([organisationRoute.addChildren([])]),
    paidDriversListRoute.addChildren([
      paidDriverTableRoute,
      paidDriverRoute.addChildren([paidDriverDetailRoute]),
    ]),
    paymentListRoute.addChildren([
      paymentRoute.addChildren([paymentDetailRoute]),
    ]),
    invoicesRoute.addChildren([
      invoiceTableRoute,
      invoiceCreateRoute,
      invoiceRecordLayoutRoute.addChildren([
        invoiceRoute.addChildren([invoiceDetailRoute, invoiceActivityRoute]),
      ]),
    ]),
    settingsRoute.addChildren([
      settingsGeneralRoute,
      settingsMarketingRoute,
      settingsAvailabilityRoute,
      settingsInsuranceRoute,
      settingsExtrasRoute,
      settingNotificationRoute,
      settingBillingRoute,
      settingsSuppliersRoute,
      settingsVehicleRoute,
      settingsOfficeRoute,
      settingsReportRoute,
      settingsCitiesRoute,
      settingsOrganisationRoute.addChildren([settingsOrganisationMembersRoute]),
      settingsOrganisationsRoute,
      settingIntegrationsRoute.addChildren([
        settingIntegrationsListRoute,
        settingsIntegrationRotue,
      ]),
      settingsDefaultRatesRoute,
    ]),
  ]),

  loginLayoutRoute.addChildren([
    signInRoute,
    signUpRoute,
    changeOrganisationRoute,

    onboardingRoute.addChildren([
      onboardingCreateOrJoinRoute,
      onboardingCreateOrgRoute,
      onboardingPricingRoute,
    ]),
  ]),
]);

// Create the router using your route tree
export const router = createRouter({
  routeTree,
  context: {
    // auth will initially be undefined
    // We'll be passing down the auth state from within a React component
    auth: undefined!,
    user: undefined!,
  },
});
