import { TextInput } from "@/lib/Components/Form/Inputs/TextInput";
import { Contact, ContactType } from "@/gql/graphql";
import { FormDivider } from "@/lib/Components/Form/Components/FormDivider";
import { SelectInput } from "@/lib/Components/Form/Inputs/SelectInput";

export function ContactDetailsForm({
  contact,
}: {
  contact?: Pick<Contact, "type">;
}) {
  return (
    <>
      <TextInput
        name={"name"}
        label={"Name"}
        isEditable={
          contact
            ? ![
                ContactType.User,
                ContactType.Organisation,
                ContactType.System,
              ].includes(contact.type)
            : true
        }
      />

      <FormDivider />
      <TextInput
        name={"legal_name"}
        label={"Legal name"}
        tooltip="Used on formal documents"
      />
      <TextInput
        name={"company_number"}
        label={"Business number"}
        optionalLabel
      />
      <TextInput
        name={"purchase_order_number"}
        label={"Default PO #"}
        optionalLabel
      />

      <SelectInput
        name="billing_cycle_day_of_month"
        label="Billing cycle date"
        options={[
          {
            label: "Day of the month",
            options: Array.from({ length: 31 }, (_, i) => ({
              label: `${i + 1}${nthNumber(i + 1)}`,
              value: (i + 1) as unknown as string,
            })),
          },
        ]}
      />
    </>
  );
}

const nthNumber = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
